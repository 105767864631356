import React, { useContext, useReducer } from "react";
import ConfigurationReducer from "context/configuration/reducer";
import ConfigurationState from "context/configuration/state";

const ConfigurationContext = React.createContext();

const ConfigurationProvider = ({ children }) => {
  const contextValue = useReducer(ConfigurationReducer, ConfigurationState);
  return (
    <ConfigurationContext.Provider value={contextValue}>
      {children}
    </ConfigurationContext.Provider>
  );
};
export default ConfigurationProvider;

export const useConfigurationProvider = () => {
  const contextValue = useContext(ConfigurationContext);
  return contextValue;
};
