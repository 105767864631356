const CurrencyState = {
  currencies: {},
  createDialogIsOpen: false,
  editDialogIsOpen: false,
  destroyDialogIsOpen: false,
  selectedRow: null,
  action: null,
};

export default CurrencyState;
