import React from "react";
import AppProvider from "./app/provider";
import ArticleProvider from "./article/provider";
import UserProvider from "./auth/provider";
import CategoryProvider from "./category/provider";
import HighlightProvider from "./highlight/provider";
import RoleProvider from "./role/provider";
import UsersProvider from "./user/provider";
import ConfigurationProvider from "./configuration/provider";
import QuestionAnswerProvider from "./questionAnswer/provider";
import SeoProvider from "./seo/provider";
import CurrencyProvider from "./currency/provider";
import SettingsProvider from "./settings/provider";

const ContextProvider = ({ children }) => {
  return (
    <AppProvider>
      <UserProvider>
        <UsersProvider>
          <RoleProvider>
            <ArticleProvider>
              <HighlightProvider>
                <CategoryProvider>
                  <ConfigurationProvider>
                    <QuestionAnswerProvider>
                      <SeoProvider>
                        <CurrencyProvider>
                          <SettingsProvider>{children}</SettingsProvider>
                        </CurrencyProvider>
                      </SeoProvider>
                    </QuestionAnswerProvider>
                  </ConfigurationProvider>
                </CategoryProvider>
              </HighlightProvider>
            </ArticleProvider>
          </RoleProvider>
        </UsersProvider>
      </UserProvider>
    </AppProvider>
  );
};

export default ContextProvider;
