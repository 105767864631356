import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import BackButton from "components/BackButton";

const ErrorsLayout = () => {
  return (
    <Grid item xs={12} display="flex" height="100vh" alignItems="center">
      <Grid container>
        <Outlet />
        <Grid item xs={12} textAlign="center">
          <BackButton type="button" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorsLayout;
