import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";
import { CREATE_META_TAG, DASHBOARD, EDIT_META_TAG, MANAGE_META_TAGS } from "constants/routes";
import { SUPER_ADMIN_ROLE } from "constants";

const MetaTags = Loadable(lazy(() => import("views/seo/metaTags")));
const CreateMetaTag = Loadable(lazy(() => import("views/seo/metaTags/forms/create")));
const EditMetaTags = Loadable(lazy(() => import("views/seo/metaTags/forms/edit")));

const SeoRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: MANAGE_META_TAGS,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <MetaTags />
        </HasRoles>
      ),
    },
    {
      path: CREATE_META_TAG,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <CreateMetaTag />
        </HasRoles>
      ),
    },
    {
      path: EDIT_META_TAG,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <EditMetaTags />
        </HasRoles>
      ),
    },
  ],
};

export default SeoRoutes;
