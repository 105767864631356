import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import useUser from "hooks/useUser";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "./Button";
import { DASHBOARD, SIGN_IN } from "constants/routes";
import { IconDashboard, IconLogin } from "@tabler/icons";

const BackButton = ({ type = "icon", IconProps, ButtonProps }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const buttonHref = isLoggedIn ? DASHBOARD : SIGN_IN;
  const buttonLabel = isLoggedIn ? (
    <FormattedMessage id="sidebar.dashboard.title" />
  ) : (
    <FormattedMessage id="auth.signIn" />
  );
  const buttonIcon = isLoggedIn ? (
    <IconDashboard color="white" {...IconProps} />
  ) : (
    <IconLogin color="white" {...IconProps} />
  );
  return (
    <>
      {type === "icon" ? (
        <Tooltip title={buttonLabel}>
          <IconButton onClick={() => navigate(buttonHref)} {...ButtonProps}>
            {buttonIcon}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={() => navigate(buttonHref)}
          startIcon={buttonIcon}
          {...ButtonProps}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );
};

export default BackButton;
