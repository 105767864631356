import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import createEmotionCache from "utils/createEmotionCache";
import { IntlProvider } from "react-intl";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import RTL from "utils/rtl";
import { SnackbarProvider } from "notistack";
import FaLocale from "lang/fa.json";
import ArLocale from "lang/ar.json";
import usePermissions from "hooks/usePermissions";
import { getCookie, setCookie } from "utils/cookie";
import {
  RTL_LOCALES,
  DEFAULT_LOCALE,
  TOKEN_KEY,
  NOTIFICATION_KEY,
} from "constants";
import "styles/app.css";
// ==============================|| APP ||============================== //

const clientSideEmotionCache = createEmotionCache();

const messages = {
  fa: FaLocale,
  ar: ArLocale,
};

const getDirection = (locale) => (RTL_LOCALES.includes(locale) ? "rtl" : "ltr");

const App = ({ emotionCache = clientSideEmotionCache }) => {
  const [ready, setReady] = useState(false);
  const { state } = useApp(),
    { identity } = useAuth(),
    { notificationPermissionIsDenied, requestNotificationPermission } =
      usePermissions(),
    token = getCookie(TOKEN_KEY),
    locale = state.locale,
    direction = getDirection(locale),
    checkNotificationPermission = () => {
      requestNotificationPermission(onGrant, onDeny);

      function onGrant() {
        setCookie(NOTIFICATION_KEY, 1);
      }

      function onDeny() {
        setCookie(NOTIFICATION_KEY, 0);
      }
    };

  useEffect(() => {
    if (notificationPermissionIsDenied) checkNotificationPermission();
  }, [notificationPermissionIsDenied]);

  const render = () => (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      language={locale}
    >
      <StyledEngineProvider injectFirst>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={themes(state)}>
            <CssBaseline />
            <IntlProvider
              locale={locale}
              messages={messages[locale]}
              defaultLocale={DEFAULT_LOCALE}
            >
              {direction === "rtl" ? (
                <RTL>
                  <SnackbarProvider
                    maxSnack={3}
                    TransitionProps={{ direction: "left" }}
                  >
                    <NavigationScroll>
                      <Routes />
                    </NavigationScroll>
                  </SnackbarProvider>
                </RTL>
              ) : (
                <SnackbarProvider
                  maxSnack={3}
                  TransitionProps={{ direction: "left" }}
                >
                  <NavigationScroll>
                    <Routes />
                  </NavigationScroll>
                </SnackbarProvider>
              )}
            </IntlProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </GoogleReCaptchaProvider>
  );

  useEffect(() => {
    token ? identity().then(() => setReady(true)) : setReady(true);
    document.dir = direction;
    document.documentElement.lang = locale;
  }, []);

  return ready && render();
};

export default App;
