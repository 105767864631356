const CategoryState = {
  categories: [],
  createDialogIsOpen: false,
  editDialogIsOpen: false,
  deleteDialogIsOpen: false,
  selectedRow: null,
  action: null,
};

export default CategoryState;
