import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthRoute from "middlewares/authRoute";
import { CATEGORIES, DASHBOARD } from "constants/routes";
import HasRoles from "middlewares/hasRoles";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const Categories = Loadable(lazy(() => import("views/category")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: DASHBOARD,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <DashboardDefault />
        </HasRoles>
      ),
    },

    {
      path: CATEGORIES,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <Categories />
        </HasRoles>
      ),
    },
  ],
};

export default MainRoutes;
