import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MainLayout from "layout/MainLayout";
const QuestionAnswer = Loadable(
  lazy(() => import("views/questionAnswer/index"))
);
const CreateQuestion = Loadable(
  lazy(() => import("views/questionAnswer/forms/create"))
);
const EditQuestion = Loadable(
  lazy(() => import("views/questionAnswer/forms/edit"))
);
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";
import {
  CREATE_QUESTION,
  EDIT_QUESTION,
  QUESTION_ANSWER,
} from "constants/routes";
import { SUPER_ADMIN_ROLE } from "constants";

const QuestionAnswerRoutes = {
  path: QUESTION_ANSWER,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: QUESTION_ANSWER,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <QuestionAnswer />
        </HasRoles>
      ),
    },
    {
      path: CREATE_QUESTION,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <CreateQuestion />
        </HasRoles>
      ),
    },
    {
      path: EDIT_QUESTION,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE]}>
          <EditQuestion />
        </HasRoles>
      ),
    },
  ],
};

export default QuestionAnswerRoutes;
