import {
  ADD_ROLE,
  DELETE_ROLE,
  SET_ROLE_ACTION,
  SET_SELECTED_ROLE,
  STORE_ROLES,
  TOGGLE_CREATE_ROLE_DIALOG,
  TOGGLE_DESTROY_ROLE_DIALOG,
  TOGGLE_EDIT_ROLE_DIALOG,
  TOGGLE_PERMISSIONS_DIALOG,
  UPDATE_ROLE,
} from "constants/actions";

const UsersReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_ROLES:
      return {
        ...state,
        roles: payload,
      };
    case ADD_ROLE:
      return {
        ...state,
        roles: {
          ...state,
          data: [payload, ...state.roles.data],
        },
      };
    case UPDATE_ROLE:
      const roleIndex = state.roles.data.findIndex(
        (role) => role.id === state.selectedRow
      );
      if (roleIndex >= 0) state.roles.data[roleIndex] = payload;
      return {
        ...state,
      };
    case DELETE_ROLE:
      state.roles.data = state.roles.data.filter(
        (item) => item.id !== state.selectedRow
      );
      return {
        ...state,
      };
    case TOGGLE_CREATE_ROLE_DIALOG:
      return {
        ...state,
        createDialogIsOpen: !state.createDialogIsOpen,
      };
    case TOGGLE_EDIT_ROLE_DIALOG:
      return {
        ...state,
        editDialogIsOpen: !state.editDialogIsOpen,
      };
    case TOGGLE_DESTROY_ROLE_DIALOG:
      return {
        ...state,
        destroyDialogIsOpen: !state.destroyDialogIsOpen,
      };
    case TOGGLE_PERMISSIONS_DIALOG:
      return {
        ...state,
        permissionsDialogIsOpen: !state.permissionsDialogIsOpen,
      };
    case SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRow: payload,
      };
    case SET_ROLE_ACTION:
      return {
        ...state,
        action: payload,
      };
    default:
      return state;
  }
};

export default UsersReducer;
