export const USER_PREFIX = "/user";
export const ADMIN_PREFIX = "/admin";
export const ACCOUNT_PREFIX = "/account";
export const STATISTICS_PREFIX = "/statistics";
export const BACKEND_STORAGE = "/storage";
export const LOGIN = `${ADMIN_PREFIX}/login`;
export const LOGOUT = `${USER_PREFIX}/account/logout`;
export const FORGOT_PASSWORd = `${USER_PREFIX}/forget-password`;
export const SPEICIAL_DISEASES = `${USER_PREFIX}/list-diseases`;
export const USER_DETAILS = `${USER_PREFIX}/account/details`;
export const COUTNRY_LIST = `${USER_PREFIX}/list-country`;
export const MAIN_API = `${process.env.REACT_APP_BACKEND_API}`;
export const PROFIle_API = `${process.env.REACT_APP_PROFILE_BACKEND_API}/user/profile`;
export const LIVE_API = process.env.REACT_APP_LIVE_BACKEND_API;
export const ARTICLE = `${PROFIle_API}/article`;
export const ARTICLE_COMMENTS = `${PROFIle_API}/article/comment`;
export const COMMENTS = `${PROFIle_API}/comment`;
export const HIGHLIGHT = `${PROFIle_API}/highlight`;
export const EDIT_AVATAR = `${USER_PREFIX}/account/avatar`;
export const EDIT_PROFILE = `${USER_PREFIX}/account/edit-info`;
export const GET_USERS_BY_UUID = `${USER_PREFIX}/uuid`;
export const DOCTORS = `${MAIN_API}/user/list-doctors`;
export const GET_USERS = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/user`;
export const ROLES = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/role`;
export const USER_ROLES = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}${USER_PREFIX}/role`;
export const UPDATE_USER = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/user`;
export const RESET_USER_PASSWORD = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}${USER_PREFIX}/reset-password`;
export const RESET_ADMIN_PASSWORD = `${USER_PREFIX}/account/reset-password`;
export const REGISTER_USER = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/user`;
export const GET_USER_STAT_BY_ROLES = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}${STATISTICS_PREFIX}/users-roles`;
export const CATEGORIES = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/category`;
export const GUEST_CATEGORIES = `${MAIN_API}${USER_PREFIX}/category`;
export const CONFIGURATION = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/configuration`;
export const QUESTION_ANSWER = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/question`;
export const META_TAG = `${MAIN_API}${ADMIN_PREFIX}${ACCOUNT_PREFIX}/meta-tag`;
export const CURRENCIES = `${ADMIN_PREFIX}${ACCOUNT_PREFIX}/currency`;
export const SETTINGS = `${ADMIN_PREFIX}${ACCOUNT_PREFIX}/setting`;
