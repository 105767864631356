import React, { useContext, useReducer } from "react";
import CurrencyReducer from "context/currency/reducer";
import CurrencyState from "context/currency/state";

const CurrencyContext = React.createContext();

const CurrencyProvider = ({ children }) => {
  const contextValue = useReducer(CurrencyReducer, CurrencyState);
  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};
export default CurrencyProvider;

export const useCurrencyProvider = () => {
  const contextValue = useContext(CurrencyContext);
  return contextValue;
};
