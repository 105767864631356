import {
    ADD_NEW_HIGHLIGHT,
    CLEAR_HIGHLIGHTS,
    CLOSE_CREATE_HIGHLiGHT_DIALOG,
    CLOSE_DESTROY_CONFIRM_DIALOG,
    CLOSE_EDIT_HIGHLiGHT_DIALOG,
    OPEN_CREATE_HIGHLiGHT_DIALOG,
    OPEN_DESTROY_CONFIRM_DIALOG,
    OPEN_EDIT_HIGHLiGHT_DIALOG,
    REMOVE_HIGHLIGHT,
    STORE_HIGHLIGHT,
    STORE_HIGHLIGHTS
} from 'constants/actions';

const HighlightReducer = (state, { type, payload }) => {
    switch (type) {
        case STORE_HIGHLIGHTS:
            return {
                ...state,
                highlights: payload
            };
        case STORE_HIGHLIGHT:
            return {
                ...state,
                highlight: payload
            };
        case ADD_NEW_HIGHLIGHT:
            if (payload.forList)
                return {
                    ...state,
                    highlights: {
                        ...state.highlights,
                        data: [payload.highlight, ...state.highlights.data]
                    }
                };
            else {
                state.highlights.data.unshift(payload.highlight);
                return {
                    ...state
                };
            }
        case REMOVE_HIGHLIGHT:
            state.highlights.data = state.highlights.data.filter((item) => item.id != payload);
            return {
                ...state
            };
        case CLEAR_HIGHLIGHTS:
            return {
                ...state,
                highlights: [],
                highlight: {},
                createDialogIsOpen: false,
                editDialogIsOpen: false,
                confirmDestroyDialogIsOpen: false
            };
        case OPEN_CREATE_HIGHLiGHT_DIALOG:
            return {
                ...state,
                createDialogIsOpen: true
            };
        case CLOSE_CREATE_HIGHLiGHT_DIALOG:
            return {
                ...state,
                createDialogIsOpen: false
            };
        case OPEN_EDIT_HIGHLiGHT_DIALOG:
            return {
                ...state,
                editDialogIsOpen: true
            };
        case CLOSE_EDIT_HIGHLiGHT_DIALOG:
            return {
                ...state,
                editDialogIsOpen: false
            };
        case OPEN_DESTROY_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDestroyDialogIsOpen: true
            };
        case CLOSE_DESTROY_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDestroyDialogIsOpen: false
            };
        default:
            return state;
    }
};

export default HighlightReducer;
