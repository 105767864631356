import {
  CLOSE_EDIT_SETTING_DIALOG,
  OPEN_EDIT_SETTING_DIALOG,
  SET_SELECTED_SETTING,
  SET_SETTINGS_ACTION,
  STORE_SETTINGS,
  UPDATE_SETTING,
} from "constants/actions";

const SettingsReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_SETTINGS:
      return {
        ...state,
        settings: payload,
      };
    case UPDATE_SETTING:
      const settingIndex = state.settings.data.findIndex(
        (item) => item.id === payload.id
      );
      if (settingIndex >= 0) state.settings.data[settingIndex] = payload;
      return {
        ...state,
      };
    case SET_SETTINGS_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_SETTING:
      return {
        ...state,
        selectedRow: payload,
      };
    case OPEN_EDIT_SETTING_DIALOG:
      return {
        ...state,
        editDialogIsOpen: true,
      };
    case CLOSE_EDIT_SETTING_DIALOG:
      return {
        ...state,
        editDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
