export const ENV_IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const SEO_PREFIX = "/seo";
export const DASHBOARD = "/";
export const FRONTEND_HOME = ENV_IS_DEVELOPMENT
  ? "http://localhost:3000"
  : "https://afzalolatebae.com";
export const USER_PANEL = ENV_IS_DEVELOPMENT
  ? "http://localhost:3001"
  : "https://app.afzalolatebae.com";
export const AUTH = "/auth";
export const SIGN_IN = `${AUTH}/login`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const EDIT_USER_ARTICLES = `${USER_PANEL}/articles?user_id=:id`;
export const EDIT_USER_HIGHLIGHTS = `${USER_PANEL}/highlights?user_id=:id`;
export const PROFILE = "/profile";
export const EDIT_PROFILE = `${PROFILE}/edit`;
export const USERS = "/users";
export const ROLES = "/roles";
export const EDIT_USERS = `${USERS}/edit/:id`;
export const REGISTER = `${USERS}/register`;
export const CATEGORIES = "/categories";
export const CONFIGURATION = "/configuration";
export const QUESTION_ANSWER = "/question-answer";
export const CREATE_QUESTION = `${QUESTION_ANSWER}/create`;
export const EDIT_QUESTION = `${QUESTION_ANSWER}/edit/:id`;
export const MANAGE_META_TAGS = `${SEO_PREFIX}/meta-tags`;
export const CREATE_META_TAG = `${SEO_PREFIX}/meta-tags/create`;
export const EDIT_META_TAG = `${SEO_PREFIX}/meta-tags/edit/:id`;
export const CURRENCIES = "/currencies";
