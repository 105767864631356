import ErrorsLayout from "layout/ErrorsLayout";
import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";

const NotFound = Loadable(lazy(() => import("views/errors/404")));
const ServerError = Loadable(lazy(() => import("views/errors/500")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ErrorsRoutes = {
  path: "/",
  element: <ErrorsLayout />,
  children: [
    {
      path: "/500",
      element: <ServerError />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default ErrorsRoutes;
