import React, { useContext, useReducer } from "react";
import SeoReducer from "context/seo/reducer";
import SeoState from "context/seo/state";

const SeoContext = React.createContext();

const SeoProvider = ({ children }) => {
  const contextValue = useReducer(SeoReducer, SeoState);
  return (
    <SeoContext.Provider value={contextValue}>{children}</SeoContext.Provider>
  );
};
export default SeoProvider;

export const useSeoProvider = () => {
  const contextValue = useContext(SeoContext);
  return contextValue;
};
