import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import AuthRoute from "middlewares/authRoute";

// project imports
import Loadable from "ui-component/Loadable";

const Currencies = Loadable(lazy(() => import("views/currency/index")));
import { CURRENCIES, DASHBOARD } from "constants/routes";
import HasRoles from "middlewares/hasRoles";

const CurrenciesRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: CURRENCIES,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <Currencies />
        </HasRoles>
      ),
    },
  ],
};

export default CurrenciesRoutes;
