import React, { useContext, useReducer } from "react";
import RoleReducer from "context/role/reducer";
import RoleState from "context/role/state";

const RoleContext = React.createContext();

const RoleProvider = ({ children }) => {
  const contextValue = useReducer(RoleReducer, RoleState);
  return (
    <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>
  );
};
export default RoleProvider;

export const useRoleProvider = () => {
  const contextValue = useContext(RoleContext);
  return contextValue;
};
