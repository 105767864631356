const usePermissions = () => {
  const notificationIsSupported = "Notification" in window;
  const notificationPermissionIsDenied =
    notificationIsSupported && Notification.permission !== "granted";
  const notificationPermissionIsGranted =
    notificationIsSupported && Notification.permission === "granted";
  const requestNotificationPermission = (
    onGranted = () => {},
    onDenied = () => {}
  ) => {
    if (!notificationIsSupported) {
      alert(
        "Your browser does not support notification, Please use a modern browser like Chrome, Firefox, etc"
      );
      return false;
    }
    Notification.requestPermission().then((permission) =>
      permission === "granted" ? onGranted(permission) : onDenied(permission)
    );
    return true;
  };

  return {
    notificationPermissionIsGranted,
    notificationPermissionIsDenied,
    requestNotificationPermission,
  };
};

export default usePermissions;
