// assets
import { FormattedMessage } from "react-intl";
import { CONFIGURATION } from "constants/routes";
import { IconSettings } from "@tabler/icons";

// constant
const icons = { IconSettings };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const configuration = {
  id: "configuration",
  title: <FormattedMessage id="sidebar.configuration.title" />,
  type: "group",
  roles: ["super-admin"],
  children: [
    {
      id: "manageConfiguration",
      title: <FormattedMessage id="sidebar.configuration.title" />,
      type: "item",
      url: CONFIGURATION,
      icon: icons.IconSettings,
      breadcrumbs: false,
    },
  ],
};

export default configuration;
