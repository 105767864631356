import {
  CLOSE_DESTROY_META_TAG_DIALOG,
  DELETE_META_TAG,
  OPEN_DESTROY_META_TAG_DIALOG,
  SET_SELECTED_META_TAG,
  SET_SEO_ACTION,
  STORE_META_TAGS,
} from "constants/actions";

const SeoReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_META_TAGS:
      return {
        ...state,
        metaTags: payload,
      };
    case SET_SEO_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_META_TAG:
      return {
        ...state,
        selectedMetaTag: payload,
      };
    case DELETE_META_TAG:
      state.metaTags.data = state.metaTags.data.filter(
        (metaTag) => metaTag.id !== state.selectedMetaTag
      );
      return {
        ...state,
      };
    case OPEN_DESTROY_META_TAG_DIALOG:
      return {
        ...state,
        destroyMetaTagAlertDialogIsOpen: true,
      };
    case CLOSE_DESTROY_META_TAG_DIALOG:
      return {
        ...state,
        destroyMetaTagAlertDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default SeoReducer;
