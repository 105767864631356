import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthRoute from "middlewares/authRoute";
import { REGISTER, USERS, EDIT_PROFILE, DASHBOARD } from "constants/routes";
import HasRoles from "middlewares/hasRoles";

const Users = Loadable(lazy(() => import("views/users/list")));
const EditUser = Loadable(lazy(() => import("views/users/forms/edit/index")));
const RegisterForms = Loadable(
  lazy(() => import("views/users/forms/register"))
);
const EditProfile = Loadable(lazy(() => import("views/profile/forms/edit")));

const UserRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: USERS,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <Users />
        </HasRoles>
      ),
    },
    {
      path: USERS.concat("/edit/:id"),
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <EditUser />
        </HasRoles>
      ),
    },
    {
      path: REGISTER,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <RegisterForms />
        </HasRoles>
      ),
    },
    {
      path: EDIT_PROFILE,
      element: <EditProfile />,
    },
  ],
};

export default UserRoutes;
