// assets
import { FormattedMessage } from "react-intl";
import { MANAGE_META_TAGS } from "constants/routes";
import { SUPER_ADMIN_ROLE } from "constants";
import { IconSeo } from "@tabler/icons";

// constant
const icons = { IconSeo };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const seo = {
  id: "seo",
  title: <FormattedMessage id="sidebar.seo.title" />,
  type: "group",
  roles: [SUPER_ADMIN_ROLE],
  children: [
    {
      id: "manageMetaTags",
      title: <FormattedMessage id="sidebar.seo.manageMetaTags" />,
      type: "item",
      url: MANAGE_META_TAGS,
      icon: icons.IconSeo,
      breadcrumbs: false,
    },
  ],
};

export default seo;
