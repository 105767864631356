import React, { useContext, useReducer } from "react";
import UsersReducer from "context/user/reducer";
import UsersState from "context/user/state";

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const contextValue = useReducer(UsersReducer, UsersState);
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

export const useUserProvider = () => {
  const contextValue = useContext(UserContext);
  return contextValue;
};
