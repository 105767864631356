// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_USER = "set_user";
export const CLEAR_USER = "clear_user";
export const STORE_ARTICLES = "store_articles";
export const STORE_ARTICLE = "store_article";
export const REMOVE_ARTICLE = "remove_article";
export const CLEAR_ARTICLES = "clear_articles";
export const OPEN_DESTROY_CONFIRM_DIALOG = "open_destroy_confirm_dialog";
export const CLOSE_DESTROY_CONFIRM_DIALOG = "close_destroy_confirm_dialog";
export const OPEN_REPLY_DIALOG = "open_reply_dialog";
export const CLOSE_REPLY_DIALOG = "close_reply_dialog";
export const STORE_HIGHLIGHTS = "store_highlights";
export const STORE_HIGHLIGHT = "store_highlight";
export const ADD_NEW_HIGHLIGHT = "add_new_highlight";
export const REMOVE_HIGHLIGHT = "remove_highlight";
export const CLEAR_HIGHLIGHTS = "clear_highlights";
export const OPEN_CREATE_HIGHLiGHT_DIALOG = "open_create_highlight_dialog";
export const CLOSE_CREATE_HIGHLiGHT_DIALOG = "close_create_highlight_dialog";
export const OPEN_EDIT_HIGHLiGHT_DIALOG = "open_edit_highlight_dialog";
export const CLOSE_EDIT_HIGHLiGHT_DIALOG = "close_edit_highlight_dialog";
export const CHANGE_LOCALE = "change_locale";

// User actions

export const SET_AVATAR = "set_avatar";
export const DELETE_AVATAR = "delete_avatar";
export const UPDATE_USER = "update_user";

// Users actions
export const STORE_USERS = "store_users";
export const UPDATE_USERS = "update_users";
export const OPEN_CONFIRM_DOCTOR_DIALOG = "open_confirm_doctor_dialog";
export const CLOSE_CONFIRM_DOCTOR_DIALOG = "close_confirm_doctor_dialog";
export const OPEN_UNCONFIRM_DOCTOR_DIALOG = "open_unconfirm_doctor_dialog";
export const CLOSE_UNCONFIRM_DOCTOR_DIALOG = "close_unconfirm_doctor_dialog";
export const SET_USERS_ACTION = "set_users_action";

// Categories actions

export const STORE_CATEGORIES = "store_categories";
export const ADD_CATEGORY = "add_category";
export const UPDATE_CATEGORY = "update_category";
export const DELETE_CATEGORY = "delete_category";
export const OPEN_CREATE_CATEGORY_DIALOG = "open_create_category_dialog";
export const CLOSE_CREATE_CATEGORY_DIALOG = "close_create_category_dialog";
export const OPEN_EDIT_CATEGORY_DIALOG = "open_edit_category_dialog";
export const CLOSE_EDIT_CATEGORY_DIALOG = "close_edit_category_dialog";
export const OPEN_DELETE_CATEGORY_DIALOG = "open_delete_category_dialog";
export const CLOSE_DELETE_CATEGORY_DIALOG = "close_delete_category_dialog";
export const SET_SELECTED_CATEGORY = "set_selected_row";
export const SET_CATEGORY_ACTION = "set_category_action";

// Roles actions

export const STORE_ROLES = "store_roles";
export const ADD_ROLE = "add_role";
export const UPDATE_ROLE = "update_role";
export const DELETE_ROLE = "delete_role";
export const TOGGLE_CREATE_ROLE_DIALOG = "toggle_create_role_dialog";
export const TOGGLE_EDIT_ROLE_DIALOG = "toggle_edit_role_dialog";
export const TOGGLE_DESTROY_ROLE_DIALOG = "toggle_destroy_role_dialog";
export const TOGGLE_PERMISSIONS_DIALOG = "toggle_permissions_dialog";
export const SET_SELECTED_ROLE = "set_selected_role";
export const SET_ROLE_ACTION = "set_role_action";

// Configuration actions

export const STORE_CONFIGURATION = "store_configuration";
export const SET_CONFIGURATION_ACTION = "set_configuration_action";
export const SET_SELECTED_CONFIG = "set_selected_config";
export const UPDATE_CONFIG = "update_config";
export const OPEN_ACTIVATION_CONFIGURATION_DIALOG =
  "open_activation_configuration_dialog";
export const CLOSE_ACTIVATION_CONFIGURATION_DIALOG =
  "close_activation_configuration_dialog";
export const OPEN_INACTIVATION_CONFIGURATION_DIALOG =
  "open_inactivation_configuration_dialog";
export const CLOSE_INACTIVATION_CONFIGURATION_DIALOG =
  "close_inactivation_configuration_dialog";

// Question-Answer actions
export const STORE_QUESTIONS = "store_questions";
export const OPEN_QUESTION_DESTROY_DIALOG = "open_destroy_dialog";
export const CLOSE_QUESTION_DESTROY_DIALOG = "close_destroy_dialog";
export const SET_QUESTION_SELECTED_ROW = "set_question_selected_row";
export const DELETE_QUESTION = "delete_question";
export const SET_QUESTION_ACTION = "set_question_action";

// Seo action

export const STORE_META_TAGS = "store_meta_tags";
export const SET_SEO_ACTION = "set_seo_action";
export const SET_SELECTED_META_TAG = "set_selected_meta_tag";
export const DELETE_META_TAG = "delete_meta_tag";
export const OPEN_DESTROY_META_TAG_DIALOG = "open_destroy_meta_tag_dialog";
export const CLOSE_DESTROY_META_TAG_DIALOG = "close_destroy_meta_tag_dialog";

// Currency actions

export const STORE_CURRENCIES = "store_currencies";
export const ADD_CURRENCY = "add_currency";
export const UPDATE_CURRENCY = "update_currency";
export const DELETE_CURRENCY = "delete_currency";
export const SET_CURRENCIES_ACTION = "set_currencies_action";
export const SET_SELECTED_CURRENCY = "set_selected_currency";
export const OPEN_CREATE_CURRENCY_DIALOG = "open_create_currency_dialog";
export const CLOSE_CREATE_CURRENCY_DIALOG = "close_create_currency_dialog";
export const OPEN_EDIT_CURRENCY_DIALOG = "open_edit_currency_dialog";
export const CLOSE_EDIT_CURRENCY_DIALOG = "close_edit_currency_dialog";
export const OPEN_DESTROY_CURRENCY_DIALOG = "open_destroy_currency_dialog";
export const CLOSE_DESTROY_CURRENCY_DIALOG = "close_destroy_currency_dialog";

// Settings actions

export const STORE_SETTINGS = "store_settings";
export const UPDATE_SETTING = "update_setting";
export const SET_SETTINGS_ACTION = "set_settings_action";
export const SET_SELECTED_SETTING = "set_selected_setting";
export const OPEN_EDIT_SETTING_DIALOG = "open_edit_setting_dialog";
export const CLOSE_EDIT_SETTING_DIALOG = "close_edit_setting_dialog";
