import {
  CLOSE_CONFIRM_DOCTOR_DIALOG,
  CLOSE_UNCONFIRM_DOCTOR_DIALOG,
  OPEN_CONFIRM_DOCTOR_DIALOG,
  OPEN_UNCONFIRM_DOCTOR_DIALOG,
  SET_USERS_ACTION,
  STORE_USERS,
  UPDATE_USERS,
} from "constants/actions";

const UserReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_USERS:
      return {
        ...state,
        users: payload,
      };
    case UPDATE_USERS:
      const userIndex = state.users.data.findIndex(
        (item) => item.id === payload.id
      );
      if (userIndex >= 0) state.users.data[userIndex] = payload.user;
      return {
        ...state,
      };
    case OPEN_CONFIRM_DOCTOR_DIALOG:
      return {
        ...state,
        confirmDoctorDialogIsOpen: true,
      };
    case CLOSE_CONFIRM_DOCTOR_DIALOG:
      return {
        ...state,
        confirmDoctorDialogIsOpen: false,
      };
    case OPEN_UNCONFIRM_DOCTOR_DIALOG:
      return {
        ...state,
        unconfirmDoctorDialogIsOpen: true,
      };
    case CLOSE_UNCONFIRM_DOCTOR_DIALOG:
      return {
        ...state,
        unconfirmDoctorDialogIsOpen: false,
      };
    case SET_USERS_ACTION:
      return {
        ...state,
        action: payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
