import { useState } from "react";
import { useNavigate } from "react-router";
import { useUserProvider } from "context/auth/provider";
import useHttp from "hooks/useHttp";
import useLanguages from "./useLanguages";
import { deleteCookie, setCookie } from "utils/cookie";
import {
  FORGOT_PASSWORd,
  LOGIN,
  LOGOUT,
  REGISTER_USER,
  USER_DETAILS,
} from "constants/api";
import { TOKEN_KEY, CROSS_DOMAIN_COOKIE } from "constants";
import { DASHBOARD, SIGN_IN, USERS } from "constants/routes";
import { CLEAR_USER, SET_USER } from "constants/actions";

const useAuth = () => {
  const [{ user, isLoggedIn }, dispatch] = useUserProvider();
  const navigate = useNavigate();
  const { _get, _post } = useHttp();
  const { formatLanguagesForAutocomplete } = useLanguages();
  const [pending, setPending] = useState(false);

  const login = async (credential = {}) =>
    await _post(LOGIN, credential).then(({ data: { data: response } }) => {
      const {
        token,
        token_expires_at,
        token_expiry_duration,
        token_type,
        roles,
        ...updatedUser
      } = response;
      setCookie(TOKEN_KEY, response.token, {
        expires: new Date(token_expires_at),
        domain: CROSS_DOMAIN_COOKIE,
      });
      updatedUser.roles = roles;
      updatedUser.permission = response.permission;
      storeUser(updatedUser);
      navigate(DASHBOARD);
    });

  const register = async (formData) => {
    delete formData.agreeTerms;
    return await _post(REGISTER_USER, formData).then(() => navigate(USERS));
  };

  const identity = async () => {
    return await _get(USER_DETAILS)
      .then(({ data: { data: user } }) => {
        const { roles, user: userDetails } = user;
        userDetails.roles = roles;
        userDetails.languages = formatLanguagesForAutocomplete(
          userDetails.languages
        );
        storeUser(userDetails);
      })
      .catch(logout);
  };

  const logout = async () => {
    setPending(true);
    await _get(LOGOUT).finally(() => {
      clearUser();
      deleteCookie(TOKEN_KEY, { domain: CROSS_DOMAIN_COOKIE });
      setPending(false);
      navigate(SIGN_IN);
    });
  };

  const forgotPassword = async (formData) => {
    return await _post(FORGOT_PASSWORd, formData);
  };

  const storeUser = (user) => dispatch({ type: SET_USER, payload: user });
  const clearUser = () => dispatch({ type: CLEAR_USER });

  return {
    user,
    isLoggedIn,
    pending,
    login,
    register,
    identity,
    logout,
    forgotPassword,
  };
};

export default useAuth;
