// assets
import { FormattedMessage } from "react-intl";
import { CATEGORIES } from "constants/routes";
import { IconCategory } from "@tabler/icons";

// constant
const icons = { IconCategory };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const category = {
  id: "category",
  title: <FormattedMessage id="sidebar.category.title" />,
  type: "group",
  roles: ["super-admin"],
  children: [
    {
      id: "manageCategory",
      title: <FormattedMessage id="sidebar.category.list.title" />,
      type: "item",
      url: CATEGORIES,
      icon: icons.IconCategory,
      breadcrumbs: false,
    },
  ],
};

export default category;
