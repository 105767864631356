// assets
import { FormattedMessage } from "react-intl";
import { ROLES, USERS } from "constants/routes";
import { IconUsers, IconShield } from "@tabler/icons";

// constant
const icons = { IconUsers, IconShield };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const users = {
  id: "users",
  title: <FormattedMessage id="sidebar.users.title" />,
  type: "group",
  roles: ["super-admin"],
  children: [
    {
      id: "manageUsers",
      title: <FormattedMessage id="sidebar.users.list.title" />,
      type: "item",
      url: USERS,
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "manageRoles",
      title: <FormattedMessage id="sidebar.roles.manage.title" />,
      type: "item",
      url: ROLES,
      icon: icons.IconShield,
      breadcrumbs: false,
    },
  ],
};

export default users;
