import { useState } from "react";
import useHttp from "./useHttp";
import {
  EDIT_AVATAR,
  EDIT_PROFILE,
  RESET_ADMIN_PASSWORD,
  RESET_USER_PASSWORD,
  SPEICIAL_DISEASES,
} from "constants/api";
import { useUserProvider } from "context/auth/provider";

const useUser = () => {
  const [{ user, isLoggedIn }, dispatch] = useUserProvider();
  const { _get, _post, _patch, _delete } = useHttp();
  const [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);

  const getSpecialDiseases = async (adaptationForListComponent = true) => {
    setPending(true);
    return await _get(SPEICIAL_DISEASES)
      .then((response) => {
        const {
          data: { data: diseases },
        } = response;
        if (adaptationForListComponent) {
          const list = [];
          diseases.map(({ id, title }) =>
            list.push({ label: title, value: id })
          );
          return list;
        }
        return response.data;
      })
      .finally(() => setPending(false));
  };

  const uploadAvatar = async (data) => {
    enablePending();
    return await _post(EDIT_AVATAR, data, {
      onUploadProgress: function (progressEvent) {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percent);
      },
    }).finally(resetProgressAndPending);
  };

  const deleteAvatar = async () => {
    enablePending();
    return await _delete(
      EDIT_AVATAR,
      {},
      {
        onUploadProgress: function (progressEvent) {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percent);
        },
      }
    ).finally(resetProgressAndPending);
  };

  const updateProfile = async (data) => {
    enablePending();
    return await _patch(EDIT_PROFILE, data).finally(disablePending);
  };

  const resetPassword = async (id, data) => {
    enablePending();
    return await _post(RESET_USER_PASSWORD.concat(`/${id}`), data).finally(
      disablePending
    );
  };

  const resetAdminPassword = async (data) => {
    enablePending();
    return await _post(RESET_ADMIN_PASSWORD, data).finally(disablePending);
  };

  const enablePending = () => setPending(true);
  const disablePending = () => setPending(false);
  const resetProgress = () => setProgress(0);
  const resetProgressAndPending = () => {
    disablePending();
    resetProgress();
  };

  return {
    user,
    isLoggedIn,
    dispatch,
    pending,
    progress,
    getSpecialDiseases,
    uploadAvatar,
    deleteAvatar,
    updateProfile,
    resetPassword,
    resetAdminPassword
  };
};

export default useUser;
