import React, { useContext, useReducer } from "react";
import AppReducer from "context/settings/reducer";
import AppState from "context/settings/state";

const SettingsContext = React.createContext();

const SettingsProvider = ({ children }) => {
  const contextValue = useReducer(AppReducer, AppState);
  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};
export default SettingsProvider;

export const useSettingsProvider = () => {
  const contextValue = useContext(SettingsContext);
  return contextValue;
};
