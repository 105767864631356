import MainLayout from "layout/MainLayout";
import AuthRoute from "middlewares/authRoute";
import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";

const Configuration = Loadable(lazy(() => import("views/configuration/index")));
import { CONFIGURATION } from "constants/routes";
import HasRoles from "middlewares/hasRoles";

const ConfigurationRoutes = {
  path: "/",
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: CONFIGURATION,
      element: (
        <HasRoles requiredRoles={["super-admin"]}>
          <Configuration />
        </HasRoles>
      ),
    },
  ],
};

export default ConfigurationRoutes;
