import { FormattedMessage } from "react-intl";

// theme constant
export const GRID_SPACING = 3;
export const DRAWER_WIDTH = 260;
export const APP_DRAWER_WIDTH = 320;
export const LOCALES = ["fa", "ar"];
export const DEFAULT_LOCALE = "fa";
export const RTL_LOCALES = ["fa", "ar"];
export const LOCALE_KEY = "locale";
export const TOKEN_KEY = "token";
export const CROSS_DOMAIN_COOKIE =
  process.env.NODE_ENV === "development" ? "localhost" : ".afzalolatebae.com";
export const SEARCH_DEBOUNCE_TIMEOUT = 1000;
export const LANGUAGES = [
  {
    label: "Abkhaz",
    nativeName: "аҧсуа",
    value: "abkhaz",
  },
  {
    label: "Afar",
    nativeName: "Afaraf",
    value: "afar",
  },
  {
    label: "Afrikaans",
    nativeName: "Afrikaans",
    value: "afrikaans",
  },
  {
    label: "Akan",
    nativeName: "Akan",
    value: "akan",
  },
  {
    label: "Albanian",
    nativeName: "Shqip",
    value: "albanian",
  },
  {
    label: "Amharic",
    nativeName: "አማርኛ",
    value: "amharic",
  },
  {
    label: "Arabic",
    nativeName: "العربية",
    value: "arabic",
  },
  {
    label: "Aragonese",
    nativeName: "Aragonés",
    value: "aragonese",
  },
  {
    label: "Armenian",
    nativeName: "Հայերեն",
    value: "armenian",
  },
  {
    label: "Assamese",
    nativeName: "অসমীয়া",
    value: "assamese",
  },
  {
    label: "Avaric",
    nativeName: "авар мацӀ, магӀарул мацӀ",
    value: "avaric",
  },
  {
    label: "Avestan",
    nativeName: "avesta",
    value: "avestan",
  },
  {
    label: "Aymara",
    nativeName: "aymar aru",
    value: "aymara",
  },
  {
    label: "Azerbaijani",
    nativeName: "azərbaycan dili",
    value: "azerbaijani",
  },
  {
    label: "Bambara",
    nativeName: "bamanankan",
    value: "bambara",
  },
  {
    label: "Bashkir",
    nativeName: "башҡорт теле",
    value: "bashkir",
  },
  {
    label: "Basque",
    nativeName: "euskara, euskera",
    value: "basque",
  },
  {
    label: "Belarusian",
    nativeName: "Беларуская",
    value: "belarusian",
  },
  {
    label: "Bengali",
    nativeName: "বাংলা",
    value: "bengali",
  },
  {
    label: "Bihari",
    nativeName: "भोजपुरी",
    value: "bihari",
  },
  {
    label: "Bislama",
    nativeName: "Bislama",
    value: "bislama",
  },
  {
    label: "Bosnian",
    nativeName: "bosanski jezik",
    value: "bosnian",
  },
  {
    label: "Breton",
    nativeName: "brezhoneg",
    value: "breton",
  },
  {
    label: "Bulgarian",
    nativeName: "български език",
    value: "bulgarian",
  },
  {
    label: "Burmese",
    nativeName: "ဗမာစာ",
    value: "burmese",
  },
  {
    label: "Catalan; Valencian",
    nativeName: "Català",
    value: "catalan; valencian",
  },
  {
    label: "Chamorro",
    nativeName: "Chamoru",
    value: "chamorro",
  },
  {
    label: "Chechen",
    nativeName: "нохчийн мотт",
    value: "chechen",
  },
  {
    label: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
    value: "chichewa; chewa; nyanja",
  },
  {
    label: "Chinese",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
    value: "chinese",
  },
  {
    label: "Chuvash",
    nativeName: "чӑваш чӗлхи",
    value: "chuvash",
  },
  {
    label: "Cornish",
    nativeName: "Kernewek",
    value: "cornish",
  },
  {
    label: "Corsican",
    nativeName: "corsu, lingua corsa",
    value: "corsican",
  },
  {
    label: "Cree",
    nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
    value: "cree",
  },
  {
    label: "Croatian",
    nativeName: "hrvatski",
    value: "croatian",
  },
  {
    label: "Czech",
    nativeName: "česky, čeština",
    value: "czech",
  },
  {
    label: "Danish",
    nativeName: "dansk",
    value: "danish",
  },
  {
    label: "Divehi; Dhivehi; Maldivian;",
    nativeName: "ދިވެހި",
    value: "divehi; dhivehi; maldivian;",
  },
  {
    label: "Dutch",
    nativeName: "Nederlands, Vlaams",
    value: "dutch",
  },
  {
    label: "English",
    nativeName: "English",
    value: "english",
  },
  {
    label: "Esperanto",
    nativeName: "Esperanto",
    value: "esperanto",
  },
  {
    label: "Estonian",
    nativeName: "eesti, eesti keel",
    value: "estonian",
  },
  {
    label: "Ewe",
    nativeName: "Eʋegbe",
    value: "ewe",
  },
  {
    label: "Faroese",
    nativeName: "føroyskt",
    value: "faroese",
  },
  {
    label: "Fijian",
    nativeName: "vosa Vakaviti",
    value: "fijian",
  },
  {
    label: "Finnish",
    nativeName: "suomi, suomen kieli",
    value: "finnish",
  },
  {
    label: "French",
    nativeName: "français, langue française",
    value: "french",
  },
  {
    label: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
    value: "fula; fulah; pulaar; pular",
  },
  {
    label: "Galician",
    nativeName: "Galego",
    value: "galician",
  },
  {
    label: "Georgian",
    nativeName: "ქართული",
    value: "georgian",
  },
  {
    label: "German",
    nativeName: "Deutsch",
    value: "german",
  },
  {
    label: "Greek, Modern",
    nativeName: "Ελληνικά",
    value: "greek, modern",
  },
  {
    label: "Guaraní",
    nativeName: "Avañeẽ",
    value: "guaraní",
  },
  {
    label: "Gujarati",
    nativeName: "ગુજરાતી",
    value: "gujarati",
  },
  {
    label: "Haitian; Haitian Creole",
    nativeName: "Kreyòl ayisyen",
    value: "haitian; haitian creole",
  },
  {
    label: "Hausa",
    nativeName: "Hausa, هَوُسَ",
    value: "hausa",
  },
  {
    label: "Hebrew (modern)",
    nativeName: "עברית",
    value: "hebrew (modern)",
  },
  {
    label: "Herero",
    nativeName: "Otjiherero",
    value: "herero",
  },
  {
    label: "Hindi",
    nativeName: "हिन्दी, हिंदी",
    value: "hindi",
  },
  {
    label: "Hiri Motu",
    nativeName: "Hiri Motu",
    value: "hiri motu",
  },
  {
    label: "Hungarian",
    nativeName: "Magyar",
    value: "hungarian",
  },
  {
    label: "Interlingua",
    nativeName: "Interlingua",
    value: "interlingua",
  },
  {
    label: "Indonesian",
    nativeName: "Bahasa Indonesia",
    value: "indonesian",
  },
  {
    label: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
    value: "interlingue",
  },
  {
    label: "Irish",
    nativeName: "Gaeilge",
    value: "irish",
  },
  {
    label: "Igbo",
    nativeName: "Asụsụ Igbo",
    value: "igbo",
  },
  {
    label: "Inupiaq",
    nativeName: "Iñupiaq, Iñupiatun",
    value: "inupiaq",
  },
  {
    label: "Ido",
    nativeName: "Ido",
    value: "ido",
  },
  {
    label: "Icelandic",
    nativeName: "Íslenska",
    value: "icelandic",
  },
  {
    label: "Italian",
    nativeName: "Italiano",
    value: "italian",
  },
  {
    label: "Inuktitut",
    nativeName: "ᐃᓄᒃᑎᑐᑦ",
    value: "inuktitut",
  },
  {
    label: "Japanese",
    nativeName: "日本語 (にほんご／にっぽんご)",
    value: "japanese",
  },
  {
    label: "Javanese",
    nativeName: "basa Jawa",
    value: "javanese",
  },
  {
    label: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
    value: "kalaallisut, greenlandic",
  },
  {
    label: "Kannada",
    nativeName: "ಕನ್ನಡ",
    value: "kannada",
  },
  {
    label: "Kanuri",
    nativeName: "Kanuri",
    value: "kanuri",
  },
  {
    label: "Kashmiri",
    nativeName: "कश्मीरी, كشميري‎",
    value: "kashmiri",
  },
  {
    label: "Kazakh",
    nativeName: "Қазақ тілі",
    value: "kazakh",
  },
  {
    label: "Khmer",
    nativeName: "ភាសាខ្មែរ",
    value: "khmer",
  },
  {
    label: "Kikuyu, Gikuyu",
    nativeName: "Gĩkũyũ",
    value: "kikuyu, gikuyu",
  },
  {
    label: "Kinyarwanda",
    nativeName: "Ikinyarwanda",
    value: "kinyarwanda",
  },
  {
    label: "Kirghiz, Kyrgyz",
    nativeName: "кыргыз тили",
    value: "kirghiz, kyrgyz",
  },
  {
    label: "Komi",
    nativeName: "коми кыв",
    value: "komi",
  },
  {
    label: "Kongo",
    nativeName: "KiKongo",
    value: "kongo",
  },
  {
    label: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
    value: "korean",
  },
  {
    label: "Kurdish",
    nativeName: "Kurdî, كوردی‎",
    value: "kurdish",
  },
  {
    label: "Kwanyama, Kuanyama",
    nativeName: "Kuanyama",
    value: "kwanyama, kuanyama",
  },
  {
    label: "Latin",
    nativeName: "latine, lingua latina",
    value: "latin",
  },
  {
    label: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
    value: "luxembourgish, letzeburgesch",
  },
  {
    label: "Luganda",
    nativeName: "Luganda",
    value: "luganda",
  },
  {
    label: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
    value: "limburgish, limburgan, limburger",
  },
  {
    label: "Lingala",
    nativeName: "Lingála",
    value: "lingala",
  },
  {
    label: "Lao",
    nativeName: "ພາສາລາວ",
    value: "lao",
  },
  {
    label: "Lithuanian",
    nativeName: "lietuvių kalba",
    value: "lithuanian",
  },
  {
    label: "Luba-Katanga",
    nativeName: "",
    value: "luba-katanga",
  },
  {
    label: "Latvian",
    nativeName: "latviešu valoda",
    value: "latvian",
  },
  {
    label: "Manx",
    nativeName: "Gaelg, Gailck",
    value: "manx",
  },
  {
    label: "Macedonian",
    nativeName: "македонски јазик",
    value: "macedonian",
  },
  {
    label: "Malagasy",
    nativeName: "Malagasy fiteny",
    value: "malagasy",
  },
  {
    label: "Malay",
    nativeName: "bahasa Melayu, بهاس ملايو‎",
    value: "malay",
  },
  {
    label: "Malayalam",
    nativeName: "മലയാളം",
    value: "malayalam",
  },
  {
    label: "Maltese",
    nativeName: "Malti",
    value: "maltese",
  },
  {
    label: "Māori",
    nativeName: "te reo Māori",
    value: "māori",
  },
  {
    label: "Marathi (Marāṭhī)",
    nativeName: "मराठी",
    value: "marathi (marāṭhī)",
  },
  {
    label: "Marshallese",
    nativeName: "Kajin M̧ajeļ",
    value: "marshallese",
  },
  {
    label: "Mongolian",
    nativeName: "монгол",
    value: "mongolian",
  },
  {
    label: "Nauru",
    nativeName: "Ekakairũ Naoero",
    value: "nauru",
  },
  {
    label: "Navajo, Navaho",
    nativeName: "Diné bizaad, Dinékʼehǰí",
    value: "navajo, navaho",
  },
  {
    label: "Norwegian Bokmål",
    nativeName: "Norsk bokmål",
    value: "norwegian bokmål",
  },
  {
    label: "North Ndebele",
    nativeName: "isiNdebele",
    value: "north ndebele",
  },
  {
    label: "Nepali",
    nativeName: "नेपाली",
    value: "nepali",
  },
  {
    label: "Ndonga",
    nativeName: "Owambo",
    value: "ndonga",
  },
  {
    label: "Norwegian Nynorsk",
    nativeName: "Norsk nynorsk",
    value: "norwegian nynorsk",
  },
  {
    label: "Norwegian",
    nativeName: "Norsk",
    value: "norwegian",
  },
  {
    label: "Nuosu",
    nativeName: "ꆈꌠ꒿ Nuosuhxop",
    value: "nuosu",
  },
  {
    label: "South Ndebele",
    nativeName: "isiNdebele",
    value: "south ndebele",
  },
  {
    label: "Occitan",
    nativeName: "Occitan",
    value: "occitan",
  },
  {
    label: "Ojibwe, Ojibwa",
    nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
    value: "ojibwe, ojibwa",
  },
  {
    label:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
    value:
      "old church slavonic, church slavic, church slavonic, old bulgarian, old slavonic",
  },
  {
    label: "Oromo",
    nativeName: "Afaan Oromoo",
    value: "oromo",
  },
  {
    label: "Oriya",
    nativeName: "ଓଡ଼ିଆ",
    value: "oriya",
  },
  {
    label: "Ossetian, Ossetic",
    nativeName: "ирон æвзаг",
    value: "ossetian, ossetic",
  },
  {
    label: "Panjabi, Punjabi",
    nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
    value: "panjabi, punjabi",
  },
  {
    label: "Pāli",
    nativeName: "पाऴि",
    value: "pāli",
  },
  {
    label: "Persian",
    nativeName: "فارسی",
    value: "persian",
  },
  {
    label: "Polish",
    nativeName: "polski",
    value: "polish",
  },
  {
    label: "Pashto, Pushto",
    nativeName: "پښتو",
    value: "pashto, pushto",
  },
  {
    label: "Portuguese",
    nativeName: "Português",
    value: "portuguese",
  },
  {
    label: "Quechua",
    nativeName: "Runa Simi, Kichwa",
    value: "quechua",
  },
  {
    label: "Romansh",
    nativeName: "rumantsch grischun",
    value: "romansh",
  },
  {
    label: "Kirundi",
    nativeName: "kiRundi",
    value: "kirundi",
  },
  {
    label: "Romanian, Moldavian, Moldovan",
    nativeName: "română",
    value: "romanian, moldavian, moldovan",
  },
  {
    label: "Russian",
    nativeName: "русский язык",
    value: "russian",
  },
  {
    label: "Sanskrit (Saṁskṛta)",
    nativeName: "संस्कृतम्",
    value: "sanskrit (saṁskṛta)",
  },
  {
    label: "Sardinian",
    nativeName: "sardu",
    value: "sardinian",
  },
  {
    label: "Sindhi",
    nativeName: "सिन्धी, سنڌي، سندھی‎",
    value: "sindhi",
  },
  {
    label: "Northern Sami",
    nativeName: "Davvisámegiella",
    value: "northern sami",
  },
  {
    label: "Samoan",
    nativeName: "gagana faa Samoa",
    value: "samoan",
  },
  {
    label: "Sango",
    nativeName: "yângâ tî sängö",
    value: "sango",
  },
  {
    label: "Serbian",
    nativeName: "српски језик",
    value: "serbian",
  },
  {
    label: "Scottish Gaelic; Gaelic",
    nativeName: "Gàidhlig",
    value: "scottish gaelic; gaelic",
  },
  {
    label: "Shona",
    nativeName: "chiShona",
    value: "shona",
  },
  {
    label: "Sinhala, Sinhalese",
    nativeName: "සිංහල",
    value: "sinhala, sinhalese",
  },
  {
    label: "Slovak",
    nativeName: "slovenčina",
    value: "slovak",
  },
  {
    label: "Slovene",
    nativeName: "slovenščina",
    value: "slovene",
  },
  {
    label: "Somali",
    nativeName: "Soomaaliga, af Soomaali",
    value: "somali",
  },
  {
    label: "Southern Sotho",
    nativeName: "Sesotho",
    value: "southern sotho",
  },
  {
    label: "Spanish; Castilian",
    nativeName: "español, castellano",
    value: "spanish; castilian",
  },
  {
    label: "Sundanese",
    nativeName: "Basa Sunda",
    value: "sundanese",
  },
  {
    label: "Swahili",
    nativeName: "Kiswahili",
    value: "swahili",
  },
  {
    label: "Swati",
    nativeName: "SiSwati",
    value: "swati",
  },
  {
    label: "Swedish",
    nativeName: "svenska",
    value: "swedish",
  },
  {
    label: "Tamil",
    nativeName: "தமிழ்",
    value: "tamil",
  },
  {
    label: "Telugu",
    nativeName: "తెలుగు",
    value: "telugu",
  },
  {
    label: "Tajik",
    nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
    value: "tajik",
  },
  {
    label: "Thai",
    nativeName: "ไทย",
    value: "thai",
  },
  {
    label: "Tigrinya",
    nativeName: "ትግርኛ",
    value: "tigrinya",
  },
  {
    label: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
    value: "tibetan standard, tibetan, central",
  },
  {
    label: "Turkmen",
    nativeName: "Türkmen, Түркмен",
    value: "turkmen",
  },
  {
    label: "Tagalog",
    nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
    value: "tagalog",
  },
  {
    label: "Tswana",
    nativeName: "Setswana",
    value: "tswana",
  },
  {
    label: "Tonga (Tonga Islands)",
    nativeName: "faka Tonga",
    value: "tonga (tonga islands)",
  },
  {
    label: "Turkish",
    nativeName: "Türkçe",
    value: "turkish",
  },
  {
    label: "Tsonga",
    nativeName: "Xitsonga",
    value: "tsonga",
  },
  {
    label: "Tatar",
    nativeName: "татарча, tatarça, تاتارچا‎",
    value: "tatar",
  },
  {
    label: "Twi",
    nativeName: "Twi",
    value: "twi",
  },
  {
    label: "Tahitian",
    nativeName: "Reo Tahiti",
    value: "tahitian",
  },
  {
    label: "Uighur, Uyghur",
    nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
    value: "uighur, uyghur",
  },
  {
    label: "Ukrainian",
    nativeName: "українська",
    value: "ukrainian",
  },
  {
    label: "Urdu",
    nativeName: "اردو",
    value: "urdu",
  },
  {
    label: "Uzbek",
    nativeName: "zbek, Ўзбек, أۇزبېك‎",
    value: "uzbek",
  },
  {
    label: "Venda",
    nativeName: "Tshivenḓa",
    value: "venda",
  },
  {
    label: "Vietnamese",
    nativeName: "Tiếng Việt",
    value: "vietnamese",
  },
  {
    label: "Volapük",
    nativeName: "Volapük",
    value: "volapük",
  },
  {
    label: "Walloon",
    nativeName: "Walon",
    value: "walloon",
  },
  {
    label: "Welsh",
    nativeName: "Cymraeg",
    value: "welsh",
  },
  {
    label: "Wolof",
    nativeName: "Wollof",
    value: "wolof",
  },
  {
    label: "Western Frisian",
    nativeName: "Frysk",
    value: "western frisian",
  },
  {
    label: "Xhosa",
    nativeName: "isiXhosa",
    value: "xhosa",
  },
  {
    label: "Yiddish",
    nativeName: "ייִדיש",
    value: "yiddish",
  },
  {
    label: "Yoruba",
    nativeName: "Yorùbá",
    value: "yoruba",
  },
  {
    label: "Zhuang, Chuang",
    nativeName: "Saɯ cueŋƅ, Saw cuengh",
    value: "zhuang, chuang",
  },
];

export const SUPER_ADMIN_ROLE = "super-admin";
export const DOCTOR_ROLE = "doctor";
export const ADVISER_ROLE = "adviser";
export const PATIENT_ROLE = "patient";
export const LEADER_ROLE = "leader";
export const LEGAL_ROLE = "legal";
export const HOSPITAL_ROLE = "hospital";
export const CLINIC_ROLE = "clinic";

export const ACTIVE_USER_STATUS = "active";
export const INACTIVE_USER_STATUS = "inactive";
export const SUSPENDED_USER_STATUS = "suspended";

export const ROLES = [
  {
    id: 1,
    label: <FormattedMessage id="roles.patient" />,
    value: PATIENT_ROLE,
  },
  {
    id: 2,
    label: <FormattedMessage id="roles.leader" />,
    value: LEADER_ROLE,
  },
  {
    id: 3,
    label: <FormattedMessage id="roles.doctor" />,
    value: DOCTOR_ROLE,
  },
  {
    id: 4,
    label: <FormattedMessage id="roles.adviser" />,
    value: ADVISER_ROLE,
  },
  {
    id: 5,
    label: <FormattedMessage id="roles.legal" />,
    value: LEGAL_ROLE,
  },
  {
    id: 6,
    label: <FormattedMessage id="roles.hospital" />,
    value: HOSPITAL_ROLE,
  },
  {
    id: 7,
    label: <FormattedMessage id="roles.clinic" />,
    value: CLINIC_ROLE,
  },
];

export const SEX = [
  {
    id: 1,
    label: <FormattedMessage id="sex.male" />,
    value: "male",
  },
  {
    id: 2,
    label: <FormattedMessage id="sex.female" />,
    value: "female",
  },
  {
    id: 3,
    label: <FormattedMessage id="sex.legal" />,
    value: "legal",
  },
];

export const GENDERS = ["male", "female", "legal"];
export const NOTIFICATION_KEY = "notification";
export const PERSIAN_ARABIC_ALPHA_REGEX = /^[\u0600-\u06FF\s]+$/;
