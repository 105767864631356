import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import UserRoutes from "./UserRoutes";
import RoleRoutes from "./RoleRoutes";
import ErrorsRoutes from "./ErrorsRoutes";
import ConfigurationRoutes from "./ConfigurationRoutes";
import QuestionAnswerRoutes from "./QuestionAnswerRoutes";
import SeoRoutes from "./SeoRoutes";
import CurrenciesRoutes from "./CurrencyRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    UserRoutes,
    RoleRoutes,
    ConfigurationRoutes,
    QuestionAnswerRoutes,
    SeoRoutes,
    CurrenciesRoutes,
    ErrorsRoutes,
  ]);
}
