// assets
import { FormattedMessage } from "react-intl";
import { CURRENCIES } from "constants/routes";
import { IconCurrency } from "@tabler/icons";

// constant
const icons = { IconCurrency };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const currency = {
  id: "currency",
  title: <FormattedMessage id="sidebar.currency.title" />,
  type: "group",
  roles: ["super-admin"],
  children: [
    {
      id: "manageCurrency",
      title: <FormattedMessage id="sidebar.currency.manage.title" />,
      type: "item",
      url: CURRENCIES,
      icon: icons.IconCurrency,
      breadcrumbs: false,
    },
  ],
};

export default currency;
