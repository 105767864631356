// import profile from "./profile";
import dashboard from "./dashboard";
import users from "./users";
import category from "./category";
import configuration from "./configuration";
import questionAnswer from "./questionAnswer";
import seo from "./seo";
import currency from "./currency";

const menuItems = {
  items: [
    dashboard,
    questionAnswer,
    category,
    users,
    configuration,
    seo,
    currency,
  ],
};

export default menuItems;
