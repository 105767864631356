const UsersState = {
  roles: [],
  createDialogIsOpen: false,
  editDialogIsOpen: false,
  permissionsDialogIsOpen: false,
  destroyDialogIsOpen: false,
  selectedRow: null,
  action: null,
};

export default UsersState;
