import config from "config";
import { DEFAULT_LOCALE } from "constants";

const AppState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  locale: DEFAULT_LOCALE,
};

export default AppState;
