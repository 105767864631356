// assets
import { FormattedMessage } from "react-intl";
import { QUESTION_ANSWER } from "constants/routes";
import { IconQuestionMark } from "@tabler/icons";
import { SUPER_ADMIN_ROLE } from "constants";

// constant
const icons = { IconQuestionMark };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const questionAnswer = {
  id: "questionAnswer",
  title: <FormattedMessage id="sidebar.questionAnswer.title" />,
  type: "group",
  roles: [SUPER_ADMIN_ROLE],
  children: [
    {
      id: "questionAnswer-index",
      title: <FormattedMessage id="sidebar.questionAnswer.manage.title" />,
      type: "item",
      url: QUESTION_ANSWER,
      icon: icons.IconQuestionMark,
      breadcrumbs: false,
    },
  ],
};

export default questionAnswer;
