import {
  CLOSE_ACTIVATION_CONFIGURATION_DIALOG,
  CLOSE_INACTIVATION_CONFIGURATION_DIALOG,
  OPEN_ACTIVATION_CONFIGURATION_DIALOG,
  OPEN_INACTIVATION_CONFIGURATION_DIALOG,
  SET_CONFIGURATION_ACTION,
  SET_SELECTED_CONFIG,
  STORE_CONFIGURATION,
  UPDATE_CONFIG,
} from "constants/actions";

const ConfigurationReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_CONFIGURATION:
      return {
        ...state,
        configurations: payload,
      };
    case SET_CONFIGURATION_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_CONFIG:
      return {
        ...state,
        selected: payload,
      };
    case UPDATE_CONFIG:
      const configIndex = state.configurations.findIndex(
        (config) => config.id === state.selected
      );
      if (configIndex >= 0) state.configurations[configIndex] = payload;
      return {
        ...state,
      };
    case OPEN_ACTIVATION_CONFIGURATION_DIALOG:
      return {
        ...state,
        activateDialogIsOpen: true,
      };
    case CLOSE_ACTIVATION_CONFIGURATION_DIALOG:
      return {
        ...state,
        activateDialogIsOpen: false,
      };
    case OPEN_INACTIVATION_CONFIGURATION_DIALOG:
      return {
        ...state,
        inactivateDialogIsOpen: true,
      };
    case CLOSE_INACTIVATION_CONFIGURATION_DIALOG:
      return {
        ...state,
        inactivateDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default ConfigurationReducer;
