import { v4 as uuidv4 } from "uuid";
import { BACKEND_STORAGE } from "constants/api";

export function capitalizeFirstLetter(string) {
  return string?.length > 0
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : "";
}

export function getRemoteStoragePath(uri, section = "main", inStorage = false) {
  if (!uri) return null;
  let result = "";
  switch (section) {
    case "live":
      result = `${process.env.REACT_APP_LIVE_BACKEND_URL}${
        inStorage ? BACKEND_STORAGE : ""
      }/${uri}`;
      break;
    case "profile":
      result = `${process.env.REACT_APP_PROFILE_BACKEND_URL}${
        inStorage ? BACKEND_STORAGE : ""
      }/${uri}`;
      break;
    default:
      result = `${process.env.REACT_APP_BACKEND_URL}${
        inStorage ? BACKEND_STORAGE : ""
      }/${uri}`;
      break;
  }
  return result;
}

export function pluck(array = [], key, condition = () => true) {
  return array.length && key
    ? array.map((item) => condition(item) && item[key])
    : [];
}

export function arrayElementsExistInArray(array1, array2) {
  return array1.some((item) => array2.includes(item));
}

export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function generateRandomNumber(max = 10000) {
  return Math.floor(Math.random() * max);
}

export function generateUuidV4() {
  return uuidv4();
}

export function getSearchParams(params) {
  const result = {};
  for (let [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
}
