import {
    CLEAR_ARTICLES,
    CLOSE_DESTROY_CONFIRM_DIALOG,
    CLOSE_REPLY_DIALOG,
    OPEN_DESTROY_CONFIRM_DIALOG,
    OPEN_REPLY_DIALOG,
    REMOVE_ARTICLE,
    STORE_ARTICLE,
    STORE_ARTICLES
} from 'constants/actions';

const ArticleReducer = (state, { type, payload }) => {
    switch (type) {
        case STORE_ARTICLES:
            return {
                ...state,
                articles: payload
            };
        case STORE_ARTICLE:
            return {
                ...state,
                article: payload
            };
        case REMOVE_ARTICLE:
            state.articles.data = state.articles.data.filter((item) => item.id != payload);
            return {
                ...state
            };
        case CLEAR_ARTICLES:
            return {
                ...state,
                articles: [],
                article: {},
                confirmDestroyDialogIsOpen: false
            };
        case OPEN_DESTROY_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDestroyDialogIsOpen: true
            };
        case CLOSE_DESTROY_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDestroyDialogIsOpen: false
            };
        case OPEN_REPLY_DIALOG:
            return {
                ...state,
                replyCommentDialogIsOpen: true
            };
        case CLOSE_REPLY_DIALOG:
            return {
                ...state,
                replyCommentDialogIsOpen: false
            };
        default:
            return state;
    }
};

export default ArticleReducer;
