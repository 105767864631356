import {
  ADD_CURRENCY,
  CLOSE_CREATE_CURRENCY_DIALOG,
  CLOSE_DESTROY_CURRENCY_DIALOG,
  CLOSE_EDIT_CURRENCY_DIALOG,
  DELETE_CURRENCY,
  OPEN_CREATE_CURRENCY_DIALOG,
  OPEN_DESTROY_CURRENCY_DIALOG,
  OPEN_EDIT_CURRENCY_DIALOG,
  SET_CURRENCIES_ACTION,
  SET_SELECTED_CURRENCY,
  STORE_CURRENCIES,
  UPDATE_CURRENCY,
} from "constants/actions";

const CategoryReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_CURRENCIES:
      console.log({ payload });
      return {
        ...state,
        currencies: payload,
      };
    case ADD_CURRENCY:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          data: [payload, ...state.currencies.data],
        },
      };
    case UPDATE_CURRENCY:
      const updatedCurrencyIndex = state.currencies.data.findIndex(
        (currency) => currency.id === state.selectedRow
      );
      if (updatedCurrencyIndex >= 0)
        state.currencies.data[updatedCurrencyIndex] = payload;
      return {
        ...state,
      };
    case DELETE_CURRENCY:
      state.currencies.data = state.currencies.data.filter(
        (currency) => currency.id !== state.selectedRow
      );
      return {
        ...state,
      };
    case SET_CURRENCIES_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedRow: payload,
      };
    case OPEN_CREATE_CURRENCY_DIALOG:
      return {
        ...state,
        createDialogIsOpen: true,
      };
    case CLOSE_CREATE_CURRENCY_DIALOG:
      return {
        ...state,
        createDialogIsOpen: false,
      };
    case OPEN_EDIT_CURRENCY_DIALOG:
      return {
        ...state,
        editDialogIsOpen: true,
      };
    case CLOSE_EDIT_CURRENCY_DIALOG:
      return {
        ...state,
        editDialogIsOpen: false,
      };
    case OPEN_DESTROY_CURRENCY_DIALOG:
      return {
        ...state,
        destroyDialogIsOpen: true,
      };
    case CLOSE_DESTROY_CURRENCY_DIALOG:
      return {
        ...state,
        destroyDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
