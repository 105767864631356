import {
  ADD_CATEGORY,
  CLOSE_CREATE_CATEGORY_DIALOG,
  CLOSE_DELETE_CATEGORY_DIALOG,
  CLOSE_EDIT_CATEGORY_DIALOG,
  DELETE_CATEGORY,
  OPEN_CREATE_CATEGORY_DIALOG,
  OPEN_DELETE_CATEGORY_DIALOG,
  OPEN_EDIT_CATEGORY_DIALOG,
  SET_CATEGORY_ACTION,
  SET_SELECTED_CATEGORY,
  STORE_CATEGORIES,
  UPDATE_CATEGORY,
} from "constants/actions";

const CategoryReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: [payload, ...state.categories.data],
        },
      };
    case UPDATE_CATEGORY:
      const categoryIndex = state.categories.data.findIndex(
        (item) => item.id === payload.id
      );
      if (categoryIndex >= 0)
        state.categories.data[categoryIndex] = payload.category;
      return {
        ...state,
      };
    case DELETE_CATEGORY:
      state.categories.data = state.categories.data.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
      };
    case OPEN_CREATE_CATEGORY_DIALOG:
      return {
        ...state,
        createDialogIsOpen: true,
      };
    case CLOSE_CREATE_CATEGORY_DIALOG:
      return {
        ...state,
        createDialogIsOpen: false,
      };
    case OPEN_EDIT_CATEGORY_DIALOG:
      return {
        ...state,
        editDialogIsOpen: true,
      };
    case CLOSE_EDIT_CATEGORY_DIALOG:
      return {
        ...state,
        editDialogIsOpen: false,
      };
    case OPEN_DELETE_CATEGORY_DIALOG:
      return {
        ...state,
        deleteDialogIsOpen: true,
      };
    case CLOSE_DELETE_CATEGORY_DIALOG:
      return {
        ...state,
        deleteDialogIsOpen: false,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedRow: payload,
      };
    case SET_CATEGORY_ACTION:
      return {
        ...state,
        action: payload,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
