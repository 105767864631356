import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import { AUTH, FORGOT_PASSWORD, SIGN_IN } from "constants/routes";
import GuestRoute from "middlewares/guestRoute";

// login option 3 routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ForgotPassword"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: AUTH,
  element: (
    <GuestRoute>
      <MinimalLayout />
    </GuestRoute>
  ),
  children: [
    {
      path: SIGN_IN,
      element: <AuthLogin />,
    },
    {
      path: FORGOT_PASSWORD,
      element: <AuthForgotPassword />,
    },
  ],
};

export default AuthenticationRoutes;
